import { Container, Header, Image, AspectRatio, rem } from "@mantine/core";

const MainHeader = () => {
  return (
    <Header
      height={60}
      sx={{
        position: "relative",
        padding: `1rem 3rem`,
        borderBottom: 0,
        backgroundColor: "transparent",
      }}
    >
      <Container
        sx={{
          height: 60,
          display: "flex",
          alignItems: "center",
        }}
        fluid
      >
        <AspectRatio ratio={1} style={{ flex: `0 0 ${rem(170)}` }}>
          <Image
            src="https://res.cloudinary.com/dgmlaarf9/image/upload/v1702758920/frn8shipzp5lpcbvqdyh.png"
          />
        </AspectRatio>
      </Container>
    </Header>
  );
};

export default MainHeader;
