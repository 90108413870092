import { createStyles, Overlay } from "@mantine/core";
import MainHeader from "./components/MainHeader";
import HeroBanner from "./components/HeroBanner";
import HeaderBorder from "./components/Shape_Dividers/HeaderBorder";
import "./App.css";
import WhyChooseUs from "./components/Sections/WhyChooseUs";
import HowItWorks from "./components/Sections/HowItWorks";
import Features from "./components/Sections/Features";
import MainFooter from "./components/Sections/MainFooter";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  banner: {
    position: "relative",
    backgroundImage:
      "url(https://images.unsplash.com/photo-1590674899484-d5640e854abe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1467&q=80)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingBottom: `3rem`,
  },
}));

const App = () => {
  const { classes } = useStyles();

  useEffect(() => {
    document.title = 'iPark - IoT Smart Parking';

    return () => {
      document.title = 'IoTech-X - IoT Solutions';
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="icon" href="https://res.cloudinary.com/dgmlaarf9/image/upload/v1702844994/fvgohipyprim0cab1rdj.ico" />
      </Helmet>
      <div
        style={{
          position: "relative",
        }}
      >
        <div className={classes.banner}>
          <Overlay
            sx={{
              background: "rgba(0, 85, 212, 0.7)",
            }}
            opacity={1}
            zIndex={0}
          />
          <div>
            <MainHeader />
            <HeroBanner />
          </div>
        </div>
        <HeaderBorder />
      </div>
      <WhyChooseUs />
      <HowItWorks />
      <Features />
      <MainFooter />
    </>
  );
};

export default App;
