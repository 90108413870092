import {
  Footer,
  Container,
  Text,
  Group,
  ActionIcon,
  createStyles,
  rem,
  Image,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `${theme.spacing.xl}`,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: rem(200),

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: "-1rem",

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

const MainFooter = () => {
  const { classes } = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <Footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Image
            mx="auto"
            src="https://res.cloudinary.com/dgmlaarf9/image/upload/v1702758920/frn8shipzp5lpcbvqdyh.png"
          />
          <Text
            size="xs"
            color="dimmed"
            className={classes.description}
            sx={{ textAlign: "center" }}
          >
            Navigate with Ease, Park with Peace - Your Smart Solution to
            Stress-Free Parking!
          </Text>
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          © {currentYear}{" "}
          <a
            href="https://www.iotech-x.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            IoTech-X.com
          </a>
          . All rights reserved.
        </Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon
            size="lg"
            sx={{ marginRight: 6 }}
            component="a"
            href="https://www.facebook.com/iotechx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                enableBackground: "new 0 0 512 512",
              }}
              xmlSpace="preserve"
              viewBox="0 0 512 512"
            >
              <path
                fill="#1877f2"
                d="M512 256c0 127.78-93.62 233.69-216 252.89V330h59.65L367 256h-71v-48.02c0-20.25 9.92-39.98 41.72-39.98H370v-63s-29.3-5-57.31-5c-58.47 0-96.69 35.44-96.69 99.6V256h-65v74h65v178.89C93.62 489.69 0 383.78 0 256 0 114.62 114.62 0 256 0s256 114.62 256 256z"
              />
              <path
                fill="#fff"
                d="M355.65 330 367 256h-71v-48.021c0-20.245 9.918-39.979 41.719-39.979H370v-63s-29.296-5-57.305-5C254.219 100 216 135.44 216 199.6V256h-65v74h65v178.889c13.034 2.045 26.392 3.111 40 3.111s26.966-1.066 40-3.111V330z"
              />
            </svg>
          </ActionIcon>
          <ActionIcon
            size="lg"
            sx={{ marginRight: 6 }}
            component="a"
            href="https://www.linkedin.com/company/iotechx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                enableBackground: "new 0 0 64 64",
              }}
              xmlSpace="preserve"
              viewBox="0 0 64 64"
            >
              <g fillRule="evenodd">
                <path
                  fill="#2864b4"
                  d="M48 64H16A16 16 0 0 1 0 48V16A16 16 0 0 1 16 0h32a16 16 0 0 1 16 16v32a16 16 0 0 1-16 16"
                  data-original="#2864b4"
                />
                <path
                  fill="#417dcd"
                  d="M30 18h18A9 9 0 0 0 48.92.046C48.614.029 48.311 0 48 0H16A16 16 0 0 0 0 16v32a30 30 0 0 1 30-30"
                  data-original="#417dcd"
                />
                <path
                  fill="#0f4b9b"
                  d="M48 32a16 16 0 1 0 16 16V16a16 16 0 0 1-16 16"
                  data-original="#0f4b9b"
                />
              </g>
              <rect
                width={8}
                height={27}
                x={12}
                y={25}
                fill="#fff"
                data-original="#ffffff"
                rx={2}
              />
              <circle
                cx={16}
                cy={16}
                r={5}
                fill="#fff"
                data-original="#ffffff"
              />
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M34 28.64A9.216 9.216 0 0 1 42 24c5.087 0 10 3 10 11v15a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V36a5 5 0 0 0-10 0v14a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V27a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2z"
                data-original="#ffffff"
              />
            </svg>
          </ActionIcon>
          <ActionIcon
            size="lg"
            sx={{ marginRight: 6 }}
            component="a"
            href="https://www.instagram.com/iotechx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                enableBackground: "new 0 0 512 512",
              }}
              xmlSpace="preserve"
              viewBox="0 0 512 512"
            >
              <radialGradient
                id="b"
                cx={104.957}
                cy={447.447}
                r={564.646}
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#fae100" />
                <stop offset={0.054} stopColor="#fadc04" />
                <stop offset={0.117} stopColor="#fbce0e" />
                <stop offset={0.183} stopColor="#fcb720" />
                <stop offset={0.251} stopColor="#fe9838" />
                <stop offset={0.305} stopColor="#ff7950" />
                <stop offset={0.492} stopColor="#ff1c74" />
                <stop offset={1} stopColor="#6c1cd1" />
              </radialGradient>
              <linearGradient
                id="c"
                x1={196.373}
                x2={-671.016}
                y1={222.46}
                y2={-265.446}
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#a1b5d8" stopOpacity={0} />
                <stop offset={0.309} stopColor="#90a2bd" stopOpacity={0.31} />
                <stop offset={0.755} stopColor="#7c8b9c" stopOpacity={0.757} />
                <stop offset={1} stopColor="#748290" />
              </linearGradient>
              <linearGradient
                id="d"
                x1={256}
                x2={256}
                y1={451.966}
                y2={531.774}
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#fae100" stopOpacity={0} />
                <stop offset={0.307} stopColor="#fca800" stopOpacity={0.306} />
                <stop offset={0.627} stopColor="#fe7300" stopOpacity={0.627} />
                <stop offset={0.869} stopColor="#ff5200" stopOpacity={0.867} />
                <stop offset={1} stopColor="#ff4500" />
              </linearGradient>
              <linearGradient id="a">
                <stop offset={0} stopColor="#833ab4" stopOpacity={0} />
                <stop offset={1} stopColor="#833ab4" />
              </linearGradient>
              <linearGradient
                xlinkHref="#a"
                id="e"
                x1={226.872}
                x2={100.161}
                y1={226.148}
                y2={99.436}
                gradientUnits="userSpaceOnUse"
              />
              <linearGradient
                xlinkHref="#a"
                id="f"
                x1={350.9}
                x2={287.656}
                y1={468.287}
                y2={170.138}
                gradientUnits="userSpaceOnUse"
              />
              <linearGradient
                xlinkHref="#a"
                id="g"
                x1={374.965}
                x2={120.941}
                y1={374.965}
                y2={120.941}
                gradientUnits="userSpaceOnUse"
              />
              <linearGradient
                xlinkHref="#a"
                id="h"
                x1={393.807}
                x2={309.806}
                y1={221.263}
                y2={137.262}
                gradientUnits="userSpaceOnUse"
              />
              <linearGradient
                id="i"
                x1={357.658}
                x2={150.543}
                y1={155.05}
                y2={362.165}
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopColor="#833ab4" />
                <stop offset={0.092} stopColor="#9c3495" />
                <stop offset={0.293} stopColor="#dc2546" />
                <stop offset={0.392} stopColor="#fd1d1d" />
                <stop offset={0.559} stopColor="#fc6831" />
                <stop offset={0.689} stopColor="#fc9b40" />
                <stop offset={0.752} stopColor="#fcaf45" />
                <stop offset={0.781} stopColor="#fdb750" />
                <stop offset={0.866} stopColor="#fecb6a" />
                <stop offset={0.942} stopColor="#ffd87a" />
                <stop offset={1} stopColor="#ffdc80" />
              </linearGradient>
              <path
                fill="url(#b)"
                d="M503.234 91.578c-4.66-43.664-39.144-78.156-82.812-82.812-109.508-11.688-219.336-11.688-328.84 0-43.668 4.66-78.156 39.148-82.816 82.812-11.688 109.504-11.688 219.336 0 328.84 4.66 43.668 39.148 78.156 82.812 82.816 109.504 11.688 219.336 11.688 328.844 0 43.668-4.66 78.152-39.148 82.812-82.816 11.688-109.504 11.688-219.332 0-328.84zm0 0"
                data-original="url(#b)"
              />
              <path
                fill="url(#c)"
                d="M475.387 110.098c-4.133-38.746-34.735-69.352-73.485-73.489-97.172-10.367-194.632-10.367-291.804 0-38.746 4.137-69.352 34.743-73.489 73.489-10.367 97.172-10.367 194.632 0 291.8 4.137 38.75 34.743 69.356 73.489 73.489 97.172 10.37 194.632 10.37 291.8 0 38.75-4.133 69.356-34.739 73.489-73.489 10.37-97.168 10.37-194.628 0-291.8zm0 0"
                data-original="url(#c)"
              />
              <path
                fill="url(#d)"
                d="M7.672 409.805c.351 3.539.715 7.078 1.094 10.617 4.66 43.664 39.148 78.152 82.816 82.812 109.504 11.688 219.336 11.688 328.84 0 43.668-4.66 78.152-39.148 82.812-82.812.38-3.54.743-7.078 1.098-10.617zm0 0"
                data-original="url(#d)"
              />
              <path
                fill="url(#e)"
                d="M503.234 420.418a1547.774 1547.774 0 0 0 8.711-176.7l-117.03-117.03c-14.622-16.692-35.978-27.11-61.071-28.012-51.606-1.86-103.375-1.766-154.989.008-42.867 1.476-72.843 30.289-80.53 72.636-1.356 7.477-2.169 15.051-3.235 22.582v124.149c.59 4.023 1.457 8.027 1.726 12.074 1.72 25.758 12.305 47.82 29.254 62.746l119.094 119.09c58.445.41 116.895-2.496 175.258-8.727 43.668-4.66 78.152-39.148 82.812-82.816zm0 0"
                data-original="url(#e)"
              />
              <path
                fill="url(#f)"
                d="M503.234 420.422c-4.656 43.66-39.152 78.156-82.812 82.812a1548.657 1548.657 0 0 1-175.254 8.727L126.254 393.047c13.785 12.066 31.754 19.414 52.605 20.2 51.602 1.937 103.383 1.886 154.985.026 46.672-1.687 80.445-36.23 81.902-82.902a2373.981 2373.981 0 0 0 0-148.793c-.664-21.531-8.223-40.476-20.754-54.812L511.95 243.723a1550.386 1550.386 0 0 1-8.715 176.699zm0 0"
                data-original="url(#f)"
              />
              <path
                fill="url(#g)"
                d="M316.414 200.559c-14.992-16.325-36.504-26.567-60.414-26.567-45.29 0-82.008 36.719-82.008 82.008 0 23.91 10.242 45.422 26.567 60.414l189.738 189.738a1564.245 1564.245 0 0 0 30.121-2.918c43.668-4.66 78.156-39.148 82.816-82.816a1564.245 1564.245 0 0 0 2.918-30.121zm0 0"
                data-original="url(#g)"
              />
              <path
                fill="url(#h)"
                d="M511.008 311.152 358.305 158.453c-3.563-4.676-9.176-7.71-15.508-7.71-10.774 0-19.512 8.734-19.512 19.51 0 6.333 3.035 11.946 7.711 15.509l177.281 177.285a1537.438 1537.438 0 0 0 2.73-51.895zm0 0"
                data-original="url(#h)"
              />
              <path
                fill="url(#i)"
                d="M95.09 193.902c1.066-7.53 1.879-15.105 3.234-22.582 7.684-42.347 37.664-71.16 80.531-72.636 51.614-1.774 103.383-1.868 154.989-.008 46.656 1.68 80.445 36.226 81.902 82.898 1.55 49.559 1.55 99.238 0 148.797-1.457 46.672-35.234 81.215-81.898 82.899-51.606 1.863-103.387 1.91-154.989-.028-46.664-1.754-78.922-36.379-82.043-83.12-.27-4.044-1.136-8.052-1.726-12.075V193.902zM256.043 385.61c23.617 0 47.258.707 70.844-.164 36.98-1.37 59.726-23.441 60.59-60.386a2973.28 2973.28 0 0 0 0-138.172c-.864-36.938-23.625-59.524-60.59-60.309a3343.88 3343.88 0 0 0-140.805 0c-36.684.77-59.496 22.899-60.492 59.43a2579.097 2579.097 0 0 0 0 139.933c.996 36.528 23.808 58.145 60.496 59.504 23.289.867 46.637.164 69.957.164zm0 0"
                data-original="url(#i)"
              />
              <g fill="#fff">
                <path
                  d="M95.09 193.902c1.066-7.53 1.879-15.105 3.234-22.582 7.684-42.347 37.664-71.16 80.531-72.636 51.614-1.774 103.383-1.868 154.989-.008 46.656 1.68 80.445 36.226 81.902 82.898 1.55 49.559 1.55 99.238 0 148.797-1.457 46.672-35.234 81.215-81.898 82.899-51.606 1.863-103.387 1.91-154.989-.028-46.664-1.754-78.922-36.379-82.043-83.12-.27-4.044-1.136-8.052-1.726-12.075V193.902zM256.043 385.61c23.617 0 47.258.707 70.844-.164 36.98-1.37 59.726-23.441 60.59-60.386a2973.28 2973.28 0 0 0 0-138.172c-.864-36.938-23.625-59.524-60.59-60.309a3343.88 3343.88 0 0 0-140.805 0c-36.684.77-59.496 22.899-60.492 59.43a2579.097 2579.097 0 0 0 0 139.933c.996 36.528 23.808 58.145 60.496 59.504 23.289.867 46.637.164 69.957.164zm0 0"
                  data-original="#ffffff"
                />
                <path
                  d="M256 173.996c-45.29 0-82.008 36.715-82.008 82.004 0 45.293 36.719 82.008 82.008 82.008 45.293 0 82.008-36.715 82.008-82.008 0-45.29-36.715-82.004-82.008-82.004zm0 135.777c-29.7 0-53.773-24.074-53.773-53.773S226.3 202.227 256 202.227 309.773 226.3 309.773 256 285.7 309.773 256 309.773zm106.305-139.519c0 10.773-8.735 19.508-19.508 19.508s-19.512-8.735-19.512-19.508c0-10.777 8.738-19.512 19.512-19.512s19.508 8.735 19.508 19.512zm0 0"
                  data-original="#ffffff"
                />
              </g>
            </svg>
          </ActionIcon>
          <ActionIcon
            size="lg"
            component="a"
            href="https://www.youtube.com/@iotechx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{
                enableBackground: "new 0 0 512 512",
              }}
              viewBox="0 0 461.001 461.001"
            >
              <path
                fill="#f61c0d"
                d="M365.257 67.393H95.744C42.866 67.393 0 110.259 0 163.137v134.728c0 52.878 42.866 95.744 95.744 95.744h269.513c52.878 0 95.744-42.866 95.744-95.744V163.137c0-52.878-42.866-95.744-95.744-95.744zm-64.751 169.663-126.06 60.123c-3.359 1.602-7.239-.847-7.239-4.568V168.607c0-3.774 3.982-6.22 7.348-4.514l126.06 63.881c3.748 1.899 3.683 7.274-.109 9.082z"
                data-original="#f61c0d"
              />
            </svg>
          </ActionIcon>
        </Group>
      </Container>
    </Footer>
  );
};

export default MainFooter;
