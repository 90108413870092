import React from "react";
import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import App from "./App";
import IotechApp from "./IotechApp";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MantineProvider
    theme={{
      fontFamily: "Golos Text, sans-serif",
      globalStyles: (theme) => ({
        body: {
          backgroundColor: "#f5f5f5",
        },
      }),
    }}
    withGlobalStyles
    withNormalizeCSS
  >
    <Router>
      <Routes>
        <Route exact path="/" element={<IotechApp />} />
        <Route path="/products/ipark" element={<App />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </Router>
  </MantineProvider>
);
