import { Container, SimpleGrid, Text, ThemeIcon, Title } from "@mantine/core";

const HowItWorks = () => {
  return (
    <div
      style={{
        overflow: "hidden",
        padding: "4rem 0",
        margin: "1rem 0",
        textAlign: "center",
        backgroundColor: "#fff",
      }}
    >
      <Container size="lg">
        <Title mb="lg">How it works?</Title>
        <Container my="lg" size={660} p={0}>
          <Text color="dimmed">
            Welcome to our IoT smart parking system! Our system is designed to
            help you manage your parking lots efficiently and provide your
            customers with a seamless parking experience. Here's how it works:
          </Text>
        </Container>
        <SimpleGrid
          cols={3}
          spacing={50}
          breakpoints={[
            { maxWidth: 900, cols: 2, spacing: 40 },
            { maxWidth: 600, cols: 1, spacing: 40 },
          ]}
          style={{ marginTop: 30 }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
                viewBox="0 0 512 512"
              >
                <path
                  d="M211 361h90v90h-90z"
                  fill="#57555c"
                  data-original="#57555c"
                />
                <path
                  d="M256 361h45v90h-45z"
                  fill="#3c3a41"
                  data-original="#3c3a41"
                />
                <path
                  d="M437 241H75c-41.4 0-75 33.6-75 75s33.6 75 75 75h362c41.4 0 75-33.6 75-75s-33.6-75-75-75z"
                  fill="#787780"
                  data-original="#787780"
                />
                <path
                  d="M512 316c0 41.4-33.6 75-75 75H256V241h181c41.4 0 75 33.6 75 75z"
                  fill="#57555c"
                  data-original="#57555c"
                />
                <circle
                  cx={75}
                  cy={316}
                  r={15}
                  fill="#fd3c65"
                  data-original="#fd3c65"
                />
                <circle
                  cx={136}
                  cy={316}
                  r={15}
                  fill="#cae8f9"
                  data-original="#cae8f9"
                />
                <circle
                  cx={196}
                  cy={316}
                  r={15}
                  fill="#cae8f9"
                  data-original="#cae8f9"
                />
                <path
                  d="M437 331H316c-8.291 0-15-6.709-15-15s6.709-15 15-15h121c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
                  fill="#1bd9de"
                  data-original="#1bd9de"
                />
                <path
                  d="M372.7 129.6c-6 5.7-15.3 5.7-21.301 0C325.901 104.099 292 90 256 90s-69.901 14.099-95.4 39.6c-6 5.7-15.3 5.7-21.301 0-5.7-6-5.7-15.3 0-21.301C170.501 77.1 211.901 60 256 60s85.499 17.1 116.7 48.3c5.701 6 5.701 15.3 0 21.3z"
                  fill="#1de8f1"
                  data-original="#1de8f1"
                />
                <path
                  d="M372.7 129.6c-6 5.7-15.3 5.7-21.301 0C325.901 104.099 292 90 256 90V60c44.099 0 85.499 17.1 116.7 48.3 5.701 6 5.701 15.3 0 21.3z"
                  fill="#1bd9de"
                  data-original="#1bd9de"
                />
                <path
                  d="M330.099 171.899c-5.698 6-15.298 6-20.999 0-14.1-14.099-33.599-21.298-53.1-21.298s-39 7.2-53.101 21.299c-5.7 6-15.3 6-20.999 0-6-5.7-6-15.3 0-20.999 19.799-20.101 47.098-30 74.099-30s54.3 9.899 74.099 30c6.001 5.698 6.001 15.298.001 20.998z"
                  fill="#1de8f1"
                  data-original="#1de8f1"
                />
                <path
                  d="M330.099 150.901c6 5.698 6 15.298 0 20.999-5.698 6-15.298 6-20.999 0-14.1-14.1-33.599-21.299-53.1-21.299v-29.7c27.001 0 54.3 9.899 74.099 30z"
                  fill="#1bd9de"
                  data-original="#1bd9de"
                />
                <path
                  d="M287.8 214.501c-6 5.7-15.3 5.7-21.301 0-2.699-2.701-6.599-4.2-10.499-4.2s-7.8 1.5-10.499 4.2c-6 5.7-15.3 5.7-21.301 0-6-6-6-15.3 0-21.301 8.399-8.401 20.099-12.9 31.8-12.9s23.401 4.499 31.8 12.9c6 6 6 15.3 0 21.301z"
                  fill="#1de8f1"
                  data-original="#1de8f1"
                />
                <path
                  d="M287.8 193.2c6 6 6 15.3 0 21.301-6 5.7-15.3 5.7-21.301 0-2.699-2.701-6.599-4.2-10.499-4.2v-30c11.7-.001 23.401 4.498 31.8 12.899z"
                  fill="#1bd9de"
                  data-original="#1bd9de"
                />
                <path
                  d="M415 86.999c-5.7 6-15.3 6-21 0C357.1 50.4 308.2 30 256 30S154.9 50.4 118.001 86.999c-5.7 6-15.3 6-21 0C91 81.299 91 71.7 97 66c42.3-42.601 99-66 159-66s116.7 23.399 159 66c6 5.7 6 15.299 0 20.999z"
                  fill="#1de8f1"
                  data-original="#1de8f1"
                />
                <path
                  d="M415 86.999c-5.7 6-15.3 6-21 0C357.1 50.4 308.2 30 256 30V0c60 0 116.7 23.399 159 66 6 5.7 6 15.299 0 20.999z"
                  fill="#1bd9de"
                  data-original="#1bd9de"
                />
                <path
                  d="M316 421H196c-41.4 0-75 34.6-75 76 0 8.399 6.599 15 15 15h240c8.401 0 15-6.601 15-15 0-41.4-33.6-76-75-76z"
                  fill="#787780"
                  data-original="#787780"
                />
                <path
                  d="M391 497c0 8.399-6.599 15-15 15H256v-91h60c41.4 0 75 34.6 75 76z"
                  fill="#57555c"
                  data-original="#57555c"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                01. Install IoT Devices
              </Text>
              <Text c="dimmed">
              Our team will install IoT devices in the parking area. These devices can help control and monitor the entire system without human intervention.
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                viewBox="0 0 50 50"
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
              >
                <path
                  d="M37.978 7.934c-.323.182-.929.525-.959.545l5.008 2.916c.03-.021.636-.364.959-.545 1.634-.918 3.117-.99 4.207-.364l-5.008-2.905c-1.09-.636-2.573-.565-4.207.353zm1.102-2.976C37.32 3.938 6 40.068 6 41.137c.01 1.5.56 2.57 1.41 3.08l-5.01-2.92c-.85-.49-1.39-1.56-1.4-3.08-.02-2.96 2.01-6.5 4.61-8.01.24-.14.72-.4.75-.42-.03-4.82 3.27-10.58 7.48-13.02 1.54-.87 2.98-1.13 4.19-.86 1.16-5.29 4.8-10.73 9.23-13.29 2.65-1.48 5.06-1.59 6.81-.58z"
                  data-original="#5793fb"
                  fill="#5793fb"
                />
                <path
                  fill="#7bb1ff"
                  d="M49 14.448c.02 3.82-2.59 8.4-5.94 10.33-1.25.71-2.43.91-3.4.68-1.54 3.56-4.17 6.8-7.23 8.57-2.12 1.19-4.07 1.41-5.6.79-.83.39-1.61.49-2.26.34-1.2 2.76-3.23 5.27-5.61 6.65-2.34 1.31-4.41 1.09-5.65-.31-.72 1-1.62 1.85-2.63 2.44-2.57 1.44-4.65.18-4.68-2.801-.01-2.97 2.01-6.51 4.62-8.02.25-.14.72-.4.74-.42-.02-4.82 3.27-10.58 7.48-13.01 1.55-.88 2.99-1.14 4.2-.87 1.16-5.29 4.8-10.73 9.23-13.29 5.35-3 9.72-.38 9.76 5.86.03-.01.63-.36.95-.54 3.3-1.85 6-.24 6.02 3.6z"
                  data-original="#7bb1ff"
                />
                <path
                  fill="#4c5671"
                  d="m18.749 38.566.01 2.922c0 .244.087.412.223.494l11.342 6.59c-.14-.081-.228-.25-.228-.494l-.005-2.922c0-.484.342-1.081.766-1.325l13.833-7.99c.212-.12.407-.13.548-.048l-11.342-6.59c-.141-.082-.331-.071-.548.048l-13.828 7.99c-.43.244-.771.841-.771 1.325z"
                  data-original="#4c5671"
                />
                <path
                  fill="#272e3a"
                  d="M30.857 43.828c-.427.247-.769.843-.767 1.33l.008 2.92c.001.49.346.686.772.44l13.832-7.986c.423-.244.77-.839.768-1.329l-.009-2.92c-.001-.487-.349-.685-.772-.44z"
                  data-original="#272e3a"
                />
                <path
                  fill="#4c5671"
                  d="m38.986 40.665.004 1.637-7.74 4.469-.004-1.638z"
                  data-original="#4c5671"
                />
                <path
                  fill="#ffdd94"
                  d="M41.83 39.182c.315-.182.573-.035.574.331 0 .363-.255.806-.57.988-.316.182-.574.036-.575-.327 0-.367.255-.81.57-.992z"
                  data-original="#ffdd94"
                />
                <path
                  fill="#77cb77"
                  d="M43.744 38.077c.319-.184.576-.037.577.33.001.362-.254.805-.573.989-.316.182-.573.035-.574-.327-.002-.367.254-.81.57-.992z"
                  data-original="#77cb77"
                />
                <path
                  fill="#373f51"
                  d="M20.822 35.473v2.368c0 .061.04.122.122.17l9.106 5.292a.634.634 0 0 0 .585-.003l12.99-7.495c.082-.05.122-.114.122-.175l-.004-2.369c0 .061-.04.122-.122.17l-12.99 7.5a.648.648 0 0 1-.585 0l-9.106-5.292c-.082-.044-.118-.106-.118-.166z"
                  data-original="#373f51"
                />
                <path
                  fill="#4c5671"
                  d="m18.749 32.27.01 2.922c0 .244.087.413.223.494l11.342 6.59c-.14-.081-.228-.25-.228-.494l-.005-2.922c0-.483.342-1.08.766-1.325l13.833-7.99c.212-.119.407-.13.548-.048l-11.342-6.59c-.141-.081-.331-.07-.548.049L19.52 30.945c-.43.244-.771.842-.771 1.325z"
                  data-original="#4c5671"
                />
                <path
                  fill="#272e3a"
                  d="M30.857 37.533c-.427.246-.769.843-.767 1.329l.008 2.92c.001.49.346.687.772.44l13.832-7.985c.423-.245.77-.84.768-1.33l-.009-2.92c-.001-.486-.349-.685-.772-.44z"
                  data-original="#272e3a"
                />
                <path
                  fill="#4c5671"
                  d="m38.986 34.369.004 1.638-7.74 4.468-.004-1.638z"
                  data-original="#4c5671"
                />
                <path
                  fill="#ffdd94"
                  d="M41.83 32.887c.315-.183.573-.036.574.33 0 .363-.255.806-.57.988-.316.183-.574.036-.575-.327 0-.366.255-.81.57-.991z"
                  data-original="#ffdd94"
                />
                <path
                  fill="#77cb77"
                  d="M43.744 31.781c.319-.184.576-.037.577.33.001.362-.254.805-.573.99-.316.182-.573.035-.574-.328-.002-.366.254-.81.57-.992z"
                  data-original="#77cb77"
                />
                <path
                  fill="#373f51"
                  d="M20.822 28.927v2.368c0 .061.04.122.122.171l9.106 5.292a.634.634 0 0 0 .585-.004l12.99-7.495c.082-.049.122-.114.122-.175l-.004-2.368c0 .06-.04.122-.122.17l-12.99 7.5a.647.647 0 0 1-.585 0l-9.106-5.292c-.082-.045-.118-.106-.118-.167z"
                  data-original="#373f51"
                />
                <path
                  fill="#4c5671"
                  d="m18.749 25.975.01 2.922c0 .244.087.412.223.494l11.342 6.59c-.14-.082-.228-.25-.228-.495l-.005-2.921c0-.484.342-1.081.766-1.326L44.69 23.25c.212-.12.407-.13.548-.049l-11.342-6.59c-.141-.081-.331-.07-.548.049L19.52 24.65c-.43.244-.771.841-.771 1.325z"
                  data-original="#4c5671"
                />
                <path
                  fill="#272e3a"
                  d="M30.857 31.237c-.427.246-.769.843-.767 1.329l.008 2.92c.001.49.346.687.772.44l13.832-7.985c.423-.244.77-.84.768-1.33l-.009-2.92c-.001-.486-.349-.684-.772-.44z"
                  data-original="#272e3a"
                />
                <path
                  fill="#4c5671"
                  d="m38.986 28.073.004 1.638-7.74 4.468-.004-1.637z"
                  data-original="#4c5671"
                />
                <path
                  fill="#ffdd94"
                  d="M41.83 26.59c.315-.181.573-.035.574.332 0 .363-.255.806-.57.988-.316.182-.574.035-.575-.327 0-.367.255-.81.57-.992z"
                  data-original="#ffdd94"
                />
                <path
                  fill="#77cb77"
                  d="M43.744 25.486c.319-.184.576-.038.577.329.001.362-.254.805-.573.99-.316.182-.573.035-.574-.327-.002-.367.254-.81.57-.992z"
                  data-original="#77cb77"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                02. Connect to the Cloud
              </Text>
              <Text c="dimmed">
              Once the devices are installed, they are connected to the cloud using both wired and wireless technology. This allows the devices to communicate with our servers and transmit real-time data about the work of the parking, such as spot availability, which can be provided to your customers.
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#90d8f9"
                  d="M322.838 33.301a10.407 10.407 0 0 0-9.933 7.302l-2.59 8.289a10.408 10.408 0 0 1-9.933 7.303h-88.763a10.407 10.407 0 0 1-9.933-7.302l-2.59-8.289a10.407 10.407 0 0 0-9.933-7.302H110.31V478.7h291.382V33.301z"
                  data-original="#90d8f9"
                />
                <path
                  fill="#75cef9"
                  d="M374.634 33.301h27.057v445.398h-27.057z"
                  data-original="#75cef9"
                />
                <path
                  fill="#4a80aa"
                  d="M401.691 20.813C401.691 9.318 392.373 0 380.878 0H131.122c-11.495 0-20.813 9.318-20.813 20.813v12.488h78.853c4.552 0 8.575 2.958 9.933 7.302l2.59 8.289a10.407 10.407 0 0 0 9.933 7.302h88.763c4.552 0 8.575-2.958 9.933-7.303l2.59-8.289a10.407 10.407 0 0 1 9.933-7.302h78.853V20.813z"
                  data-original="#4a80aa"
                />
                <path
                  fill="#407093"
                  d="M380.878 0h-27.057c11.495 0 20.813 9.318 20.813 20.813v12.488h27.057V20.813C401.691 9.318 392.373 0 380.878 0z"
                  data-original="#407093"
                />
                <path
                  fill="#4a80aa"
                  d="M401.691 491.187c0 11.495-9.318 20.813-20.813 20.813H131.122c-11.495 0-20.813-9.318-20.813-20.813v-12.488h291.382z"
                  data-original="#4a80aa"
                />
                <path
                  fill="#407093"
                  d="M380.878 512h-27.057c11.495 0 20.813-9.318 20.813-20.813v-12.488h27.057v12.488c0 11.495-9.318 20.813-20.813 20.813z"
                  data-original="#407093"
                />
                <path
                  fill="#d3effb"
                  d="M326.764 248.82v-95.145c0-3.078-1.377-5.995-3.753-7.952L262.549 95.93a10.301 10.301 0 0 0-13.098 0l-60.462 49.793a10.303 10.303 0 0 0-3.753 7.952v95.145c0 5.69 4.612 10.302 10.302 10.302h120.925c5.689 0 10.301-4.612 10.301-10.302z"
                  data-original="#d3effb"
                />
                <path
                  fill="#8bc852"
                  d="M172.748 323.705v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.18 6.18 0 0 1-6.181-6.181z"
                  data-original="#8bc852"
                />
                <path
                  fill="#f6e266"
                  d="M239.35 323.705v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.18 6.18 0 0 1-6.181-6.181z"
                  data-original="#f6e266"
                />
                <path
                  fill="#e7a52e"
                  d="M172.748 390.307v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.181 6.181 0 0 1-6.181-6.181z"
                  data-original="#e7a52e"
                />
                <path
                  fill="#dd5790"
                  d="M239.35 390.307v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.181 6.181 0 0 1-6.181-6.181z"
                  data-original="#dd5790"
                />
                <path
                  fill="#6b61b1"
                  d="M305.951 323.705v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.181 6.181 0 0 1-6.181-6.181z"
                  data-original="#6b61b1"
                />
                <path
                  fill="#e7a52e"
                  d="M305.951 390.307v-20.939a6.18 6.18 0 0 1 6.181-6.181h20.939a6.18 6.18 0 0 1 6.181 6.181v20.939a6.18 6.18 0 0 1-6.181 6.181h-20.939a6.182 6.182 0 0 1-6.181-6.181z"
                  data-original="#e7a52e"
                />
                <path
                  fill="#dd5790"
                  d="M294.643 177.364a7.691 7.691 0 0 1-4.217-1.258c-20.913-13.656-47.938-13.656-68.851 0a7.727 7.727 0 0 1-8.449-12.939c26.044-17.007 59.704-17.007 85.748 0a7.727 7.727 0 0 1-4.231 14.197zm-14.17 30.896a7.695 7.695 0 0 1-5.044-1.876c-11.076-9.554-27.781-9.554-38.857 0a7.727 7.727 0 0 1-10.094-11.7c16.831-14.519 42.215-14.519 59.046 0a7.726 7.726 0 0 1-5.051 13.576z"
                  data-original="#dd5790"
                />
                <circle
                  cx={256}
                  cy={225.821}
                  r={8.325}
                  fill="#dd5790"
                  data-original="#dd5790"
                />
                <path
                  fill="#4a80aa"
                  d="M339.253 442.718H172.747a7.726 7.726 0 1 1 0-15.452h166.505a7.725 7.725 0 0 1 7.726 7.726 7.724 7.724 0 0 1-7.725 7.726z"
                  data-original="#4a80aa"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                03. Download Our Mobile App
              </Text>
              <Text c="dimmed">
              To reserve a parking spot, users can download the mobile app on their smartphones. The app is user-friendly and easy to use, allowing users to check for available parking spots and reserve them in advance.
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#346"
                  d="M181 421v-90c0-8.291 6.709-15 15-15h60c8.291 0 15 6.709 15 15v90z"
                  data-original="#334466"
                />
                <path
                  fill="#272d4d"
                  d="M226 421h45v-90c0-8.291-6.709-15-15-15h-30z"
                  data-original="#272d4d"
                />
                <path
                  fill="#6cf"
                  d="M377 346H45c-24.814 0-45-20.186-45-45V61c0-24.814 20.186-45 45-45h362c24.814 0 45 20.186 45 45v210c0 8.291-6.709 15-15 15h-45v45c0 8.291-6.709 15-15 15z"
                  data-original="#66ccff"
                />
                <path
                  fill="#668cff"
                  d="M377 346c8.291 0 15-6.709 15-15v-45h45c8.291 0 15-6.709 15-15V61c0-24.814-20.186-45-45-45H226v330z"
                  data-original="#668cff"
                />
                <circle
                  cx={226}
                  cy={241}
                  r={15}
                  fill="#f3f5f9"
                  data-original="#f3f5f9"
                />
                <path
                  fill="#f3f5f9"
                  d="M300.209 166.791c-39.727-39.697-108.691-39.697-148.418 0-5.859 5.859-15.352 5.859-21.211 0s-5.859-15.366 0-21.211C156.127 120.048 189.994 106 226 106s69.873 14.048 95.42 39.58c5.859 5.845 5.859 15.352 0 21.211s-15.351 5.86-21.211 0z"
                  data-original="#f3f5f9"
                />
                <path
                  fill="#f3f5f9"
                  d="M257.787 209.169c-16.934-16.992-46.641-16.992-63.574 0-5.859 5.889-15.381 5.859-21.24.029-5.859-5.845-5.859-15.352-.029-21.211 28.301-28.359 77.812-28.359 106.113 0 5.83 5.859 5.83 15.366-.029 21.211-5.92 5.89-15.443 5.797-21.241-.029z"
                  data-original="#f3f5f9"
                />
                <path
                  fill="#406080"
                  d="M316 436H136c-8.291 0-15-6.709-15-15s6.709-15 15-15h180c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
                  data-original="#406080"
                />
                <path
                  fill="#346"
                  d="M316 406h-90v30h90c8.291 0 15-6.709 15-15s-6.709-15-15-15z"
                  data-original="#334466"
                />
                <path
                  fill="#406080"
                  d="M497 256h-45l-15 30-15-30h-45c-8.401 0-15 6.599-15 15v150c0 41.4 33.6 75 75 75s75-33.6 75-75V271c0-8.401-6.599-15-15-15z"
                  data-original="#406080"
                />
                <path
                  fill="#346"
                  d="M512 421V271c0-8.401-6.599-15-15-15h-45l-15 30v210c41.4 0 75-33.6 75-75z"
                  data-original="#334466"
                />
                <path
                  fill="#6cf"
                  d="M452 256v45c0 8.399-6.599 15-15 15s-15-6.601-15-15v-45z"
                  data-original="#66ccff"
                />
                <circle
                  cx={437}
                  cy={421}
                  r={15}
                  fill="#6cf"
                  data-original="#66ccff"
                />
                <circle
                  cx={437}
                  cy={361}
                  r={15}
                  fill="#6cf"
                  data-original="#66ccff"
                />
                <path
                  d="M241 241c0-8.286-6.716-15-15-15v30c8.284 0 15-6.716 15-15zm16.787-31.831c5.797 5.826 15.32 5.92 21.24.029 5.859-5.845 5.859-15.352.029-21.211-14.15-14.18-33.604-21.27-53.057-21.27v29.707c11.661.001 23.321 4.249 31.788 12.745zm42.422-42.378c5.859 5.859 15.352 5.859 21.211 0s5.859-15.366 0-21.211C295.873 120.048 262.006 106 226 106v31.018c27.173 0 54.346 9.924 74.209 29.773z"
                  data-original="#e1e6f0"
                  fill="#e1e6f0"
                />
                <path
                  fill="#668cff"
                  d="M437 316c8.401 0 15-6.601 15-15v-45h-15zm15 45c0-8.286-6.716-15-15-15v30c8.284 0 15-6.716 15-15zm0 60c0-8.286-6.716-15-15-15v30c8.284 0 15-6.716 15-15z"
                  data-original="#668cff"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                04. Park When You Are Ready
              </Text>
              <Text c="dimmed">
              When a user arrives at the parking lot, they can park in their reserved spot, if they haven't made any reservations, they can park in any spot if there is an available one. When the user enters the parking area, a timer is set, in order to calculate the sum that they have to pay.
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#7b727b"
                  d="M284.845 512H64.386c-22.758 0-41.207-18.449-41.207-41.207V41.207C23.179 18.449 41.628 0 64.386 0h220.459c22.758 0 41.207 18.449 41.207 41.207v429.586c0 22.758-18.449 41.207-41.207 41.207z"
                  data-original="#7b727b"
                />
                <path
                  fill="#686169"
                  d="M284.845 0H253.94c22.758 0 41.207 18.449 41.207 41.207v429.586c0 22.758-18.449 41.207-41.207 41.207h30.905c22.758 0 41.207-18.449 41.207-41.207V41.207C326.052 18.449 307.603 0 284.845 0z"
                  data-original="#686169"
                />
                <path
                  fill="#d6faec"
                  d="M299.268 41.207v429.586c0 7.965-6.457 14.423-14.423 14.423H64.386c-7.965 0-14.423-6.457-14.423-14.423V41.207c0-7.965 6.457-14.423 14.423-14.423H105.7a5.626 5.626 0 0 1 5.32 3.795l5.444 15.816a5.626 5.626 0 0 0 5.32 3.795h105.664a5.626 5.626 0 0 0 5.32-3.795l5.444-15.816a5.626 5.626 0 0 1 5.32-3.795h41.314c7.964.001 14.422 6.458 14.422 14.423z"
                  data-original="#d6faec"
                />
                <path
                  fill="#ccfae8"
                  d="M284.845 26.785H253.94c7.965 0 14.423 6.457 14.423 14.423v429.586c0 7.965-6.457 14.423-14.423 14.423h30.905c7.965 0 14.423-6.457 14.423-14.423V41.207c0-7.965-6.458-14.422-14.423-14.422z"
                  data-original="#ccfae8"
                />
                <path
                  fill="#2fd9e7"
                  d="M214.793 418.769h-80.354c-11.948 0-21.634-9.686-21.634-21.634s9.686-21.634 21.634-21.634h80.354c11.948 0 21.634 9.686 21.634 21.634s-9.686 21.634-21.634 21.634zm253.805-89.626H113.454c-11.169 0-20.223-9.054-20.223-20.223V102.122c0-11.169 9.054-20.223 20.223-20.223h355.143c11.169 0 20.223 9.054 20.223 20.223V308.92c.001 11.169-9.053 20.223-20.222 20.223z"
                  data-original="#2fd9e7"
                />
                <path
                  fill="#00d0e4"
                  d="M468.598 81.899h-30.905c11.169 0 20.223 9.054 20.223 20.223V308.92c0 11.169-9.054 20.223-20.223 20.223h30.905c11.169 0 20.223-9.054 20.223-20.223V102.122c0-11.168-9.054-20.223-20.223-20.223z"
                  data-original="#00d0e4"
                />
                <path
                  d="M384.571 232.459a8.82 8.82 0 0 0-8.265-5.688h-.004a8.822 8.822 0 0 0-8.187 5.494c-.023.058-.047.116-.069.176l-22.374 58.746a7.5 7.5 0 1 0 14.018 5.338l3.732-9.8h25.595l3.691 9.779a7.499 7.499 0 0 0 9.665 4.368 7.499 7.499 0 0 0 4.368-9.665zm-15.435 39.266 7.144-18.756 7.078 18.756zm82.049-43.589a7.5 7.5 0 0 0-10.377 2.193l-12.601 19.357-12.744-19.517a7.5 7.5 0 0 0-12.56 8.201l17.808 27.271-.07 28.195a7.5 7.5 0 0 0 7.481 7.519h.019a7.5 7.5 0 0 0 7.5-7.481l.07-28.221 17.667-27.14a7.5 7.5 0 0 0-2.193-10.377zm-125.106-1.365h-16.591a7.5 7.5 0 0 0-7.5 7.524v59.561c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-18.422c3.324-.017 6.976-.033 9.091-.033 13.589 0 24.645-10.908 24.645-24.315s-11.056-24.315-24.645-24.315zm0 33.629c-2.101 0-5.707.016-9.016.032-.018-3.424-.033-7.188-.033-9.348 0-1.831-.01-5.729-.02-9.314h9.068c5.228 0 9.645 4.266 9.645 9.314.001 5.051-4.416 9.316-9.644 9.316z"
                  data-original="#f8f7f7"
                  fill="#f8f7f7"
                />
                <path
                  fill="#ffe589"
                  d="M209.642 175.646h-63.871a6.18 6.18 0 0 1-6.181-6.181v-41.207a6.18 6.18 0 0 1 6.181-6.181h63.871a6.18 6.18 0 0 1 6.181 6.181v41.207a6.18 6.18 0 0 1-6.181 6.181z"
                  data-original="#ffe589"
                />
                <path
                  fill="#ff6167"
                  d="M183.887 290.847H93.231v-53.569h90.656c14.793 0 26.785 11.992 26.785 26.785 0 14.792-11.992 26.784-26.785 26.784z"
                  data-original="#ff6167"
                />
                <path
                  d="M282.171 133.191a7.499 7.499 0 0 0-7.5 7.5v12.362c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12.362a7.5 7.5 0 0 0-7.5-7.5zm127.435 0a7.499 7.499 0 0 0-7.5 7.5v12.362c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12.362a7.5 7.5 0 0 0-7.5-7.5zm27.542 0a7.499 7.499 0 0 0-7.5 7.5v12.362c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12.362a7.5 7.5 0 0 0-7.5-7.5zm-114.987 0a7.499 7.499 0 0 0-7.5 7.5v12.362c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12.362a7.5 7.5 0 0 0-7.5-7.5zm29.192 0a7.499 7.499 0 0 0-7.5 7.5v12.362c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-12.362a7.5 7.5 0 0 0-7.5-7.5z"
                  data-original="#f8f7f7"
                  fill="#f8f7f7"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                05. Pay After Parking Completion
              </Text>
              <Text c="dimmed">
              Payment is processed after the user has completed their parking session. This means that users don't have to worry about pre-paying for parking time they don't use, and parking owners don't have to worry about issuing refunds for unused parking time.
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <ThemeIcon
              variant="light"
              sx={(theme) => ({
                padding: theme.spacing.xs,
                marginRight: theme.spacing.md,
              })}
              size={60}
              radius="md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                style={{
                  enableBackground: "new 0 0 512 512",
                }}
                xmlSpace="preserve"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#516687"
                  d="M223.32 360.16h65.36v99.11h-65.36z"
                  data-original="#516687"
                />
                <path
                  fill="#465b7a"
                  d="M288.68 449.18v10.09h-65.36v-99.11h5.2l17.18 3.93s-8.82 63.27 42.98 85.09z"
                  data-original="#465b7a"
                />
                <path
                  fill="#d0e8f4"
                  d="M1.08 9.9h509.84v302.59H1.08z"
                  data-original="#d0e8f4"
                />
                <path
                  fill="#2e4059"
                  d="M1.08 309.18h509.84v63.02H1.08z"
                  data-original="#2e4059"
                />
                <path
                  fill="#eaf6ff"
                  d="M510.92 309.18V9.9H27.8v302.59h483.12z"
                  data-original="#eaf6ff"
                />
                <path
                  fill="#384868"
                  d="M510.92 309.18H27.8v63.01h483.12v-59.7zM217.3 221.61c0 3.7-3 6.7-6.7 6.7H105a6.7 6.7 0 0 1 0-13.4h105.6c3.7 0 6.7 3 6.7 6.7zm0 25.08c0 3.7-3 6.7-6.7 6.7H105c-3.7 0-6.7-3-6.7-6.7 0-3.71 3-6.7 6.7-6.7h105.6c3.7 0 6.7 2.99 6.7 6.7zm0 25.22c0 3.71-3 6.7-6.7 6.7H105c-3.7 0-6.7-2.99-6.7-6.7 0-3.7 3-6.7 6.7-6.7h105.6c3.7 0 6.7 3 6.7 6.7zm-134.13-50.3c0 3.7-3 6.7-6.7 6.7H54.76c-3.71 0-6.7-3-6.7-6.7s2.99-6.7 6.7-6.7h21.71c3.7 0 6.7 3 6.7 6.7zm0 25.08c0 3.7-3 6.7-6.7 6.7H54.76c-3.71 0-6.7-3-6.7-6.7 0-3.71 2.99-6.7 6.7-6.7h21.71c3.7 0 6.7 2.99 6.7 6.7zm0 25.22c0 3.71-3 6.7-6.7 6.7H54.76c-3.71 0-6.7-2.99-6.7-6.7 0-3.7 2.99-6.7 6.7-6.7h21.71c3.7 0 6.7 3 6.7 6.7zM399.8 54.57l-1.92 27.51c-.24 3.54-3.19 6.23-6.67 6.23-.16 0-.32 0-.47-.01a6.688 6.688 0 0 1-6.22-7.14l.86-12.38-106.5 84.35a6.661 6.661 0 0 1-4.15 1.45c-1.98 0-3.94-.87-5.26-2.55-2.29-2.89-1.81-7.11 1.1-9.4l105.99-83.95-12.46-1.63c-3.67-.48-6.24-3.84-5.76-7.51s3.83-6.24 7.51-5.76l28.14 3.7c.17.03.33.08.5.12.24.04.48.08.71.16.23.07.43.17.64.27.17.08.36.15.54.24.28.16.54.35.79.54.08.07.17.12.27.19.32.28.63.59.9.92l.04.04c.07.09.12.21.19.31.17.24.33.48.48.75.08.16.15.33.21.51.09.21.19.44.27.67.05.2.08.39.12.59.05.21.09.44.12.67.03.21.01.43.01.64.01.14.04.31.02.47z"
                  data-original="#384868"
                />
                <path
                  fill="#d0e8f4"
                  d="M274.2 340.84c0 8.11-5.3 14.99-12.64 17.39-1.73.56-3.62.87-5.56.87-10.04 0-18.2-8.21-18.2-18.25s8.16-18.2 18.2-18.2c1.94 0 3.82.31 5.56.87a18.163 18.163 0 0 1 12.64 17.32z"
                  data-original="#d0e8f4"
                />
                <path
                  fill="#eaf6ff"
                  d="M274.2 340.84c0 8.11-5.3 14.99-12.64 17.39-7.34-2.35-12.7-9.28-12.7-17.39s5.35-14.99 12.7-17.33c7.34 2.34 12.64 9.22 12.64 17.33z"
                  data-original="#eaf6ff"
                />
                <path
                  fill="#3c9aef"
                  d="M288.58 181.11h37.32v82.95h-37.32z"
                  data-original="#3c9aef"
                />
                <path
                  fill="#efb041"
                  d="M359.55 134.2h37.37v129.86h-37.37z"
                  data-original="#efb041"
                />
                <path
                  fill="#ea1c48"
                  d="M430.57 69.55h37.37v194.5h-37.37z"
                  data-original="#ea1c48"
                />
                <path
                  fill="#54a0ff"
                  d="M307.24 181.11h18.66v82.95h-18.66z"
                  data-original="#54a0ff"
                />
                <path
                  fill="#feca57"
                  d="M378.21 134.2h18.71v129.86h-18.71z"
                  data-original="#feca57"
                />
                <path
                  fill="#fc324b"
                  d="M449.23 69.55h18.71v194.5h-18.71z"
                  data-original="#fc324b"
                />
                <path
                  fill="#384868"
                  d="M491.22 270.74H266.29a6.7 6.7 0 0 1 0-13.4h224.93c3.7 0 6.7 3 6.7 6.7-.01 3.7-3 6.7-6.7 6.7z"
                  data-original="#384868"
                />
                <path
                  fill="#ea1c48"
                  d="M209.76 126.2c0 14.33-4.64 27.58-12.44 38.34a67.32 67.32 0 0 1-6.88 8l-45.78-46.34 9.74-5.1 47.92-25.08c4.79 9.02 7.44 19.32 7.44 30.18z"
                  data-original="#ea1c48"
                />
                <path
                  fill="#fc324b"
                  d="M209.76 126.2c0 14.33-4.64 27.58-12.44 38.34l-42.93-43.44 47.92-25.08c4.8 9.02 7.45 19.32 7.45 30.18z"
                  data-original="#fc324b"
                />
                <path
                  fill="#efb041"
                  d="m193.75 74.14-47.26 24.78-10.45 5.46V39.27c3.57 0 7.04.31 10.45.87 20.59 3.26 37.88 16.21 47.26 34z"
                  data-original="#efb041"
                />
                <path
                  fill="#feca57"
                  d="m193.75 74.14-47.26 24.78V40.13c20.59 3.27 37.88 16.22 47.26 34.01z"
                  data-original="#feca57"
                />
                <path
                  fill="#3c9aef"
                  d="M158.93 172.54a64.846 64.846 0 0 1-38.09 18.3c-2.5.31-5.05.46-7.65.46-35.99 0-65.16-29.11-65.16-65.06 0-35.99 29.16-65.11 65.16-65.11v65.11z"
                  data-original="#3c9aef"
                />
                <path
                  fill="#54a0ff"
                  d="M158.93 172.54a64.846 64.846 0 0 1-38.09 18.3c-32.53-3.57-57.82-31.15-57.82-64.6 0-30.85 21.41-56.64 50.17-63.37v63.37z"
                  data-original="#54a0ff"
                />
                <path
                  fill="#32435e"
                  d="M349.81 479v23.1H162.19V479c0-14.79 12.03-26.82 26.82-26.82H323c14.78.01 26.81 12.04 26.81 26.82z"
                  data-original="#32435e"
                />
                <path
                  fill="#384868"
                  d="M349.81 479v23.1H187.58V479c0-14.79 12.03-26.82 26.82-26.82H323c14.78.01 26.81 12.04 26.81 26.82z"
                  data-original="#384868"
                />
              </svg>
            </ThemeIcon>

            <div>
              <Text
                fw={700}
                fz="lg"
                sx={(theme) => ({
                  marginBottom: `calc(${theme.spacing.xs} / 2)`,
                })}
              >
                06. Monitor Occupancy and Revenue
              </Text>
              <Text c="dimmed">
              As a parking owner, you can use our admin dashboard to monitor the occupancy of your parking lot in real-time. You can track revenue and transactions, adjust pricing and availability, and generate reports and insights to help you optimize your parking for maximum profitability.
              </Text>
            </div>
          </div>
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default HowItWorks;
