import { Container, Group, Header, Image, AspectRatio, rem } from "@mantine/core";

const IotechMainHeader = () => {
  return (
    <Header
      height={60}
      sx={{
        position: "relative",
        padding: `1rem 3rem`,
        borderBottom: 0,
        backgroundColor: "transparent",
      }}
    >
      <Container
        sx={{
          height: 60,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        fluid
      >
        <Group
          sx={{
            width: "100%",
          }}
          position="apart"
        >
          <AspectRatio ratio={1} style={{ flex: `0 0 ${rem(100)}` }}>
            <Image
              src="https://res.cloudinary.com/dgmlaarf9/image/upload/v1702841545/nmumdpigtwjabxmmpohm.png"
            />
          </AspectRatio>
        </Group>
      </Container>
    </Header>
  );
};

export default IotechMainHeader;
