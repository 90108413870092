import {
  Container,
  Title,
  Text,
  Button,
  rem,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    height: rem(600),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 1,
    position: "relative",

    [theme.fn.smallerThan("sm")]: {
      height: rem(500),
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },

  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(40),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      fontSize: theme.fontSizes.sm,
    },
  },
}));

const HeroBanner = () => {
  const { classes } = useStyles();
  return (
    <Container className={classes.container}>
      <Title className={classes.title}>
        Welcome to our IoT Smart Parking solution!
      </Title>
      <Text className={classes.description} size="xl" mt="xl">
        Tired of driving around endlessly, searching for an available parking
        spot? We hear you. That's why we've developed a revolutionary IoT smart
        parking solution that makes finding a parking spot a breeze.
      </Text>
      <Button
        my="lg"
        component="a"
        href="mailto:contact@iotech-x.com"
        sx={{
          fontSize: "1rem",
        }}
      >
        Get in touch
      </Button>
    </Container>
  );
};

export default HeroBanner;
