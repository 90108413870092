import { createStyles, Overlay, Container, Title, Text, AspectRatio, Card, Image, SimpleGrid } from "@mantine/core";
import IotechMainHeader from './components/IotechMainHeader'
import IotechHeroBanner from './components/IotechHeroBanner'
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
    banner: {
      position: "relative",
      backgroundImage:
        "url(https://res.cloudinary.com/djuxwysbl/image/upload/v1699561054/IoTechX-Wallpaper_qhusvv.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      paddingBottom: `3rem`,
    },
    card: {
      transition: 'all 150ms ease',

      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow: 'var(--mantine-shadow-md)',
      },
    },
}));

const IotechApp = () => {
    const { classes } = useStyles();

    useEffect(() => {
      document.title = 'IoTech-X - IoT Solutions';
  
      return () => {
        document.title = 'iPark - IoT Smart Parking';
      };
    }, []);

    return (
      <>
        <Helmet>
          <link rel="icon" href="https://res.cloudinary.com/dgmlaarf9/image/upload/v1704215995/xtkjwba2wzmhwfvhmybf.png" />
        </Helmet>
        <div className={classes.banner}>
          <Overlay
            sx={{
              background: "rgba(54, 81, 122, 0.7)",
            }}
            opacity={1}
            zIndex={0}
          />
          <div>
            <IotechMainHeader />
            <IotechHeroBanner />
          </div>
        </div>
        <Container size="lg" px="sm" my="lg">
          <Title order={2} my="md">
            Products
          </Title>
          <SimpleGrid cols={3} spacing="lg"
      breakpoints={[
        { maxWidth: 'md', cols: 3, spacing: 'md' },
        { maxWidth: 'sm', cols: 2, spacing: 'sm' },
        { maxWidth: 'xs', cols: 1, spacing: 'sm' },
      ]}>
            <Link to="/products/ipark">
              <Card key="ipark" p="md" radius="md" className={classes.card}>
                <AspectRatio ratio={1920 / 1080}>
                  <Image src="https://images.unsplash.com/photo-1590674899484-d5640e854abe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1467&q=80" />
                </AspectRatio>
                <Text mt="md">iPark: IoT Smart Parking solution</Text>
              </Card>
            </Link>
          </SimpleGrid>
        </Container>
      </>
    )
}

export default IotechApp;