import {
    Container,
    Title,
    Text,
    rem,
    createStyles,
  } from "@mantine/core";
  
  const useStyles = createStyles((theme) => ({
    container: {
      height: rem(600),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      zIndex: 1,
      position: "relative",
  
      [theme.fn.smallerThan("sm")]: {
        height: rem(500),
        paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      },
    },
  
    title: {
      color: theme.white,
      fontSize: rem(60),
      fontWeight: 900,
      lineHeight: 1.1,
  
      [theme.fn.smallerThan("sm")]: {
        fontSize: rem(40),
        lineHeight: 1.2,
      },
  
      [theme.fn.smallerThan("xs")]: {
        fontSize: rem(28),
        lineHeight: 1.3,
      },
    },
  
    description: {
      color: theme.white,
      maxWidth: 600,
  
      [theme.fn.smallerThan("sm")]: {
        maxWidth: "100%",
        fontSize: theme.fontSizes.sm,
      },
    },
  }));
  
  const IotechHeroBanner = () => {
    const { classes } = useStyles();
    return (
      <Container className={classes.container}>
        <Title className={classes.title}>
          IoTech-X: Elevate Your World with Smart IoT Solutions
        </Title>
        <Text className={classes.description} size="xl" mt="xl">
          Revolutionize your success with IoTech-X's IoT smart solutions. Harness real-time insights, streamline operations, and propel your growth. Ready to elevate your business? Contact us now to unlock the full potential of IoT and secure your competitive advantage.
        </Text>
      </Container>
    );
  };
  
  export default IotechHeroBanner;
  